import { Pipe, PipeTransform } from '@angular/core';
import { HashMap, Translation, TranslocoService } from '@jsverse/transloco';

@Pipe({
  name: 'translocoWithFallback',
  standalone: true,
  pure: false,
})
export class TranslocoWithFallbackPipe implements PipeTransform {
  constructor(private readonly translocoService: TranslocoService) {}

  transform(key: string, params?: HashMap, fallback = ''): string {
    const activeLang = this.translocoService.getActiveLang();
    const translationDict = this.translocoService.getTranslation(activeLang);

    if (this.hasTranslationKey(translationDict, key)) {
      return this.translocoService.translate(key, params);
    }

    if (fallback) {
      return fallback;
    }
    return key;
  }

  private hasTranslationKey(dictionary: Translation, key: string): boolean {
    return key in dictionary;
  }
}
